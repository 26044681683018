@import-normalize;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}

html {
  /* overflow-x: hidden; */
  font-size: 62.5%;
}

@media screen and (min-width: 3000px) {
  html {
    font-size: 75%;
  }
}

body {
  margin: 0;
  /* overflow-x: hidden; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.4rem;
}

img {
  border-radius: 5px;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #88888844;
}

::-webkit-scrollbar-thumb {
  background: #ffffff55;
  max-height: 10px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.leaflet-tile-container img {
  width: 257px !important;
  height: 256.5px !important;
}

div.leaflet-control-attribution.leaflet-control {
  background-color: transparent;
}

.leaflet-container {
  background-color: transparent;
}

::-webkit-file-upload-button {
  cursor:pointer;
}
input[type='file']{
  cursor: pointer;
}
