.custom-carousel-swiper-container-wrapper {
  position: relative;
}
.swiper-button-next,
.swiper-button-prev {
  color: #ddd;
  background-color: #000000cc;
  width: 65px;
  height: 80px;
  transform: translateY(-20%);
}

.swiper-button-next::after,
.swiper-button-prev:after {
  transform: scale(0.5);
}

.swiper-button-prev {
  left: 0;
  border-radius: 0 50% 50% 0;
}

.swiper-button-next {
  right: 0;
  border-radius: 50% 0 0 50%;
}
